<template>
    <div class="">
        <div class="">
            <b-card class="grid-view-item overflow-hidden">
                <div id="container-map" v-if="status_mapa">
                    <l-map :center="center" :zoom="10" ref="mymap" style="height: 100%; overflow: hidden;">
                        <l-control-layers :collapsed="true" :position="layersPosition" :sort-layers="true">
                        </l-control-layers>
                        <l-tile-layer :key="tileProvider.name" :name="tileProvider.name" :url="tileProvider.url" :visible="tileProvider.visible" layer-type="base" v-for="tileProvider in tileProviders">
                        </l-tile-layer>
                        <v-marker-cluster>
                            <l-marker :draggable="item.draggable" :key="item.id" :lat-lng.sync="item.position" :visible="item.visible" v-for="item in markers">
                                <l-icon :icon-size="[40,40]">
                                  <avatarMarker
                                    :label="item.name.substr(0,2)"
                                    :status="item.status"
                                  />
                                </l-icon>
                                <l-popup>
                                    <div class="popup">
                                      <gridPrincipalItem
                                        img="pointtracker"
                                        :name="item.name"
                                        :description="$t('Labels.Alias')"
                                      />
                                      <hr>
                                      <valueItem
                                        :value="item.deveui"
                                        :description="$t('Labels.DevEui')"
                                        img="alias"
                                      />
                                      <valueItem
                                        :value="item.lat"
                                        :description="$t('Labels.Latitude')"
                                        img="georeference"
                                      />
                                      <valueItem
                                        :value="item.long"
                                        :description="$t('Labels.Longitude')"
                                        img="georeference"
                                      />
                                      <valueItem
                                        :value="item.battery"
                                        :description="$t('Labels.Battery')"
                                        img="battery"
                                        unit="%"
                                      />
                                      <hr>
                                      <alertTracker :status="item.status" />
                                      <valueItem
                                        :value="item.updated_at.split(' ')[0]"
                                        :description="item.updated_at.split(' ')[1]"
                                        img="fecha"
                                      />
                                      <div class="text-center">
                                        <b-button @click="deviceInformation(item.id)" variant="primary" size="sm">
                                          {{$t('Labels.SeeMore')}}
                                        </b-button>
                                      </div>

                                </div>
                                </l-popup>
                            </l-marker>
                        </v-marker-cluster>
                    </l-map>
                </div>
            </b-card>
        </div>
    </div>
</template>
<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LPopup,
  LControlLayers
} from 'vue2-leaflet'
import L from 'leaflet'
import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster/Vue2LeafletMarkercluster'
import gridPrincipalItem from '@core/spore-components/gridPrincipalItem/gridPrincipalItem.vue'
import valueItem from '@core/spore-components/valueItem/valueItem.vue'
import alertTracker from '@core/spore-components/alertTracker/alertTracker.vue'
import request from '@/libs/request/index'
import 'leaflet/dist/leaflet.css'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen'
import { batteryCalculate, empty } from '@/libs/tools/helpers'
import {
  BButton,
  BCard
} from 'bootstrap-vue'
import avatarMarker from '@core/spore-components/avatarMarker/avatarMarker.vue'

const tileProviders = [
  {
    name: 'Gris',
    visible: true,
    url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
  },
  {
    name: 'Calles',
    visible: false,
    url: 'https://{s}.tile.openstreetmap.fr/osmfr//{z}/{x}/{y}.png'
  },
  {
    name: 'Satelite',
    visible: false,
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png'
  }
]

const DATA = {
  code: 200,
  data: [
    {
      uuid: 'cdfbacef-3540-41b5-aae3-448bcd330df4',
      deveui: '0011223344556677',
      serial: '1111111',
      imei: '1111111',
      alias: 'tracker 1',
      status: '1',
      latlong: '20.6738686,-103.3704324',
      created_at: '2021-08-20 17:29:11',
      updated_at: '2021-08-23 11:08:06'
    },
    {
      uuid: 'cdfbacef-3540-41b5-aae3-448bcd450df4',
      deveui: '7766554433221100',
      serial: '222222',
      imei: '222222',
      alias: 'tracker 2',
      status: '0',
      latlong: '20.6738686,-103.3704345',
      created_at: '2021-08-20 17:29:11',
      updated_at: '2021-08-23 11:08:06'
    },
    {
      uuid: 'cdfbacef-3540-41b5-aae3-448bcd330df4',
      deveui: '0011223344556677',
      serial: '1111111',
      imei: '1111111',
      alias: 'tracker 1',
      status: '1',
      latlong: '20.6738686,-103.3704324',
      created_at: '2021-08-20 17:29:11',
      updated_at: '2021-08-23 11:08:06'
    },
    {
      uuid: 'cdfbacef-3540-41b5-aae3-448bcd450df4',
      deveui: '7766554433221100',
      serial: '222222',
      imei: '222222',
      alias: 'tracker 2',
      status: '0',
      latlong: '20.6738686,-103.3704345',
      created_at: '2021-08-20 17:29:11',
      updated_at: '2021-08-23 11:08:06'
    }
  ],
  msg: 'Ok'
}

export default {
  name: 'mapa',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
    LControlLayers,
    'v-marker-cluster': Vue2LeafletMarkercluster,
    gridPrincipalItem,
    valueItem,
    alertTracker,
    BButton,
    BCard,
    avatarMarker
  },
  data () {
    return {
      response: [],
      tileProviders,
      layersPosition: 'topright',
      center: [20.662396323989693, -103.37640969334421],
      staticAnchor: [12, 27],
      markers: [],
      status_mapa: false,
      DATA,
      batteryCalculate
    }
  },
  methods: {
    async getMapa () {
      const params = {
        url: 'trackers_admin/map',
        method: 'GET'
      }
      await request(params).then(data => {
        data = data.data.data
        const positions = []
        for (let i = 0; i < data.length; i++) {
          const lat = data[i].lat
          const lng = data[i].long
          positions.push([lat, lng])
          this.markers.push({
            position: { lat: lat, lng: lng },
            name: data[i].name,
            status: data[i].tipo_alerta,
            updated_at: data[i].updated_at,
            deveui: data[i].deveui,
            lat: data[i].lat,
            long: data[i].long,
            battery: batteryCalculate(data[i].battery),
            id: data[i].uuid,
            draggable: false,
            visible: true
          })
        }
        this.status_mapa = true

        const app = this
        setTimeout(function () {
          const maps = app.$refs.mymap.mapObject
          maps.addControl(new L.Control.Fullscreen())
          maps.fitBounds(positions)
        }, 1000)
      }).catch(() => {
        this.markers = []
      })
    },

    deviceInformation (uuid) {
      this.$router.push(`/tracker/detail/${uuid}`)
    }
  },
  sockets: {
    trackers (payload) {
      if (!empty(payload.lat) && payload.lat !== '0' && !empty(payload.long) && payload.long !== '0') {
        this.markers = this.markers.filter(marker => marker.id !== payload.uuid)
        this.markers.push({
          position: { lat: payload.lat, lng: payload.long },
          name: payload.name,
          status: payload.tipo_alerta,
          updated_at: payload.updated_at,
          deveui: payload.deveui,
          lat: payload.lat,
          long: payload.long,
          battery: batteryCalculate(payload.battery),
          id: payload.uuid,
          draggable: false,
          visible: true
        })
      }
    }
  },
  created () {
    this.getMapa()
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/Map.scss";
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>
